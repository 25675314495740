import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { Field, reduxForm } from 'redux-form';
import axios from "axios";
import { authToken, baseUrl } from "../../../actions";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import { Link } from "react-router-dom";
import Re from "../../../assets/images/cat.svg";


const role = localStorage.getItem("userRole");

const Content = props => {
    const { handleSubmit } = props;
    const [dataObj, setDataObj] = useState({});
    const [terms, setTerms] = useState("");
    const [data, setData] = useState({});
    const [error, setError] = useState(null);

    const loadData = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        }
        axios.get(`${baseUrl}staff/templates`, requestOptions)
            .then((res) => {
                setDataObj(res.data?.data);
            }).catch((err) => {
                console.error(err);
            });
    }


    const handleChange = (content) => {
        setTerms(content);
    }

    const changeHandler = (e) => {
        dataObj.map((item) => {
            if (item?.templateName === e.target.value) {
                setTerms(item.templateBody);
            }
        })
    }

    const submitHandler = (val) => {
        if (terms !== "" && val.templateId) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: String(terms)
            };

            let id;
            dataObj.map((item) => {
                if (item?.templateName === val.templateId) {
                    id = item.templateId;
                }
            });
            fetch(`${baseUrl}staff/templates/${id}/templateBody`, requestOptions)
                .then(res => {
                    if (res.status >= 400) {
                        throw new Error("Server responds with error!")
                    }
                    return res.json()
                })
                .then(res => {
                    setData(res);
                },
                    err => {
                        setError(err)
                    })
        } else {
            toast("Please fill the required fields");
        }
    }

    if (data.status === 200 && data.status !== undefined) {
        toast(data.data);
        data.status = undefined;
    } else if (data.status >= 400 && data.status !== undefined) {
        toast(data.data);
        data.status = undefined;
    } else {
        // console.log(data.status);
    }


    useEffect(() => {
        loadData();
        data.status = undefined;
    }, []);

    if (error) {
        return <div className="require"> {error.message} </div>
    } return (
        <div>
            <Navbar />
            <div className="dashboard-page">
                <div className="dashboard-sidebar">
                    <Sidebar />
                </div>
                <div className="dashboard-main">
                    <Card className="staff-page">
                        <div className="page-head-split">
                            <div className="head-colums">
                                <div className="head-part-tab">
                                    <img src={Re} alt="..." className="head-imgs" />
                                    <h5>Settings</h5>
                                </div>
                                <div className="tab-links">
                                    <Link to={"/" + role + "/gst"} >GST</Link>
                                    <Link to={"/" + role + "/reference-id"} >Reference Id</Link>
                                    <Link to={"/" + role + "/unit"} >Unit </Link>
                                    <Link to={"/" + role + "/group"} >Quotation Group </Link>
                                    <Link to={"/" + role + "/content-of-letter"} className="active">Content Of the Letter</Link>
                                    <Link to={"/" + role + "/terms-conditions"}>Terms & Condition</Link>
                                </div>
                            </div>
                        </div>
                        <Row style={{ padding: "50px" }}>
                            <Col md={9}>
                                <form onSubmit={handleSubmit(submitHandler)} >

                                    <Field name="templateId" component="select" className="form-control form-select" onChange={changeHandler} >
                                        <option value="" disabled={true}>Select Template *</option>
                                        {dataObj.length > 0 ? dataObj?.map((item, i) =>
                                            <option value={item?.templateName} key={i}>{item?.templateName}</option>) : null}
                                    </Field>
                                    <br />
                                    <Editor
                                        // apiKey="2j6wvm4x2pa25n680e9pbtskcfhyi2qdsvjut2c24rm83049"
                                        apiKey="7kssqlpk7ggtwzfy05fjegzfujtfd315i3iuvyry8rzamn5j"
                                        value={terms}
                                        init={{
                                            height: 350,
                                            menubar: false
                                        }}
                                        onEditorChange={handleChange}
                                    />
                                    <br></br>
                                    <button className="setting" type="submit">Save Changes</button>
                                </form>
                            </Col>
                        </Row >
                    </Card>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.qutation.loading,
        quotationTemplates: state.qutation.quotationTemplates
    }
}

const Form = reduxForm({
    form: "ContentForm",
    destroyOnUnmount: false,
    enableReinitialize: true,
})(Content);

export default connect(mapStateToProps)(Form);