import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import {
    getQutation,
    addTermsQuotation,
    changeTitleStr
} from "../../../actions";
import { Editor } from "@tinymce/tinymce-react";
import { reduxForm } from 'redux-form';
import Orange from "../../../assets/images/green2.svg";
import back from "../../../assets/images/back (3).png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
const role = localStorage.getItem("userRole");


class QuotTermsAndConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }
    prevPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`);
    }
    nextPage() {
        setTimeout(() => {
            const { history } = this.props;
            history.push(`/${role}/quotations/${this.props.match.params.add}/prepared/${this.props.match.params.id}/${this.props.match.params.quotId}`)
        }, 500)
    }
    handleChange = (content) => {
        this.setState({
            content: content
        })
    }
    submitHandler = () => {
        const { content } = this.state;
        this.props.dispatch(addTermsQuotation(this.props.match.params.quotId, String(content)));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.quotationData !== this.props.quotationData) {
            this.setState({
                content: this.props.quotationData?.termsAndConditions,
            });
        } if (prevProps.termsSuccess !== this.props.termsSuccess) {
            if (this.props.termsSuccess?.status === 200) {
                toast(this.props.termsSuccess?.data);
            } else {
                toast(this.props.termsSuccess?.data)
            }
        }
    }
    componentDidMount() {
        if (this.props.match.params.quotId !== "null") {
            this.props.dispatch(getQutation(this.props.match.params.quotId));
        }
    }
    render() {
        const { content } = this.state;
        const { handleSubmit } = this.props;
        const { history } = this.props;


        return (
            <>
                <div>
                    <Navbar />
                    <div className="dashboard-page">
                        <div className="dashboard-sidebar">
                            <Sidebar />
                        </div>
                        <div className="dashboard-main">
                            <Card className="staff-page">
                                <img src={Orange} alt="..." className="head-img" />
                                <h5 className='po_link' onClick={() => history.push("/" + role + "/quotations")}>Quotations</h5>
                                <i className="fas fa-arrow-right" style={{ margin: "10px 0 0 -10px" }}></i>
                                <h6 >{changeTitleStr(this.props.match.params.add)}</h6>
                                <hr className="line-new"></hr>
                                <div className="order-page-title">
                                    <Link to={`/${role}/quotations/${this.props.match.params.add}/customerDetails/${this.props.match.params.id}/${this.props.match.params.quotId}`}>
                                        Customer Details
                                    </Link>
                                    <Link to={`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`} >
                                        Content of the Letter
                                    </Link>
                                    <Link to={`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                        Location
                                    </Link>
                                    <Link to={`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                        Quotation Body
                                    </Link>
                                    <Link to={`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                        Footer
                                    </Link>
                                    <Link to={`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`} className="active"  >
                                        Terms & Conditions
                                    </Link>
                                    <Link to={`/${role}/quotations/${this.props.match.params.add}/prepared/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                        Prepared by
                                    </Link>
                                </div>
                                <Row style={{ padding: "20px 30px" }}>
                                    <Col md={12}>
                                        <form onSubmit={handleSubmit(this.submitHandler)}>
                                            <Editor
                                                // apiKey="2j6wvm4x2pa25n680e9pbtskcfhyi2qdsvjut2c24rm83049"
                                                apiKey="7kssqlpk7ggtwzfy05fjegzfujtfd315i3iuvyry8rzamn5j"
                                                value={content}
                                                init={{
                                                    menubar: true,
                                                    plugins: 'lineheight print importcss tinydrive searchreplace autolink autosave save directionality  visualblock  link  charmap hr  insertdatetime  wordcount  textpattern noneditable ',
                                                    toolbar: 'lineheightselect',
                                                    lineheight_formats: "2pt 3pt 4pt 5pt 6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 32pt 36pt 38pt 40pt",
                                                    height: 350,
                                                }}
                                                onEditorChange={this.handleChange}
                                            />
                                            <div className="customer-sub" style={{ marginTop: "30px" }}>
                                                <Col md={3}>
                                                    <button type="button" style={{ marginLeft: "-20px" }} className="cancel-btn" onClick={this.prevPage}>
                                                        <img style={{ marginRight: "10px" }} src={back} />
                                                        Back
                                                    </button>
                                                </Col>
                                                <Col md={6}></Col>
                                                <Col md={3}>
                                                    <button type="submit" onClick={this.nextPage} className="modal-btn save">
                                                        Save & Proceed
                                                    </button>
                                                </Col>
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.qutation.loading,
        quotationData: state.qutation.quotationData,
        termsSuccess: state.qutation.termsSuccess,
    }
}
const Form = reduxForm({
    form: "ContentForm",
    destroyOnUnmount: false,
    enableReinitialize: true,
})(QuotTermsAndConditions);

export default connect(mapStateToProps)(Form);





